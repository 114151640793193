import React from 'react'
import io from 'socket.io-client'

class App extends React.Component {
  componentDidMount() {
    const socket = io('https://nextdoor-api.now.sh', {})
    socket.on('connect', () => {
      console.log(`Socket Connected: ${socket.connected}`)
    })
  }
  render() {
    return <div>test</div>
  }
}

export default App
